import React from "react";
import { LogOpen } from "../components/LogOpen";
import { Helmet } from "react-helmet";

export default function ErrorPage() {

    return <>
    <Helmet>
      <title>Fehler</title>
    </Helmet>
    <LogOpen page="error" />
          <div class="has-text-centered">
            <h2 class="title is-4 mb-4">Oops... hier hat sich ein Fehler eingeschlichen :'(</h2>

            Die Seite konnte nicht gefunden werden!
          </div>
    </>
}