import React, { useEffect, useState } from "react";

export const LogOpen = ({ page }) => {

  useEffect(() => {
    if(localStorage && localStorage.getItem('session') === null) {
      localStorage.setItem('session', 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16)
      }));
    }

    fetch(`/api/a`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        i: localStorage.getItem('session'),
        d: {
          s: page
        }
      })
    });
  })

  return (
    <>
    </>
  )
}